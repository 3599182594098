<template>
  <div class="self-center">
    <div class="flex-row flex-wrap">
      <!-- View -->
      <b-button
        v-b-tooltip.hover.top="'Click here to view the Checkout Details'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          icon="EyeIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Review -->
      <b-button
        v-if="this.params.data.status=='Waiting Confirmation'"
        v-b-tooltip.hover.top="'Accept Order'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="updateStatus('In Process')"
      >
        <feather-icon
          class="text-warning"
          icon="CheckSquareIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <!-- Review -->
      <b-button
        v-if="this.params.data.status=='In Process'"
        v-b-tooltip.hover.top="'Ship this order'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="updateStatus('Ready to Ship')"
      >
        <feather-icon
          class="text-warning"
          icon="ChevronsRightIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <!-- Buy Again -->
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'Ready to Ship')"
        v-b-tooltip.hover.top="'Update status to Ship'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="updateStatus('Ship')"
      >
        <feather-icon
          class="text-danger"
          icon="XOctagonIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'Ship')"
        v-b-tooltip.hover.top="'Update status to Delivered'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="updateStatus('Delivered')"
      >
        <feather-icon
          class="text-danger"
          icon="XOctagonIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'Waiting Confirmation')"
        v-b-tooltip.hover.top="'Decline this Order'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="updateStatus('Rejected')"
      >
        <feather-icon
          class="text-danger"
          icon="XOctagonIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CellRendererActions',

  components: {
    //
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    viewRecord() {
      this.$router.push({ name: 'marketplace-orders-view', params: { id: this.params.data._id } })
    },

    editRecord() {
      this.$router.push({ name: 'user-manager-edit', params: { email: this.params.data.email_address } })
    },

    invokeParentMethod(popup, edit_data) {
      this.params.context.componentParent.methodFromParent(popup, edit_data)
    },

    updateStatus(status){
      
      const post_data = new FormData()
      post_data.append('status',  status)
      post_data.append('order_id',  this.params.data._id)

      this.$http
            .post(`/api/edit-orders`, post_data)
            .then(response => {
              const { success } = response.data
              const { message } = response.data
              this.isLoading = false
              if (success) {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: message,
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.$router.push('/marketplace-orders')
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: `Can't update Order, try again later`,
                      variant: 'error',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              }
            })
            .catch(error => {
              alert('ERROR')
            })
    }

  },
}
</script>
