<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-card class="">
      <div class="d-flex justify-content-between my-2">
        <!-- ITEMS PER PAGE -->
        <b-row class="ml-2">
          <ag-grid-select-page-size
            :pagination="pagination"
            @per-page-changed="pagination.perPage = $event"
          />
        </b-row>
      </div>

      <!-- AgGrid Table -->
      <div style="min-width: 50vw; min-height: 20vw;width: 100%; height: 100%;">
        <!-- <ag-grid-vue
          ref="agGridTable"
          :row-data="orders"
          :context="context"
          :components="components"
          :grid-options="gridOptions"
          style="width: 100%;"
          class="ag-theme-material"
          :column-defs="columnDefs"
          :default-col-def="defaultColDef"
          row-selection="multiple"
          col-resize-default="shift"
          :animate-rows="true"
          :pagination="true"
          :pagination-page-size="pagination.perPage"
          :suppress-pagination-panel="true"
          :is-external-filter-present="true"
          :does-external-filter-pass="true"
          @cellValueChanged="onCellChange"
          @sortChanged="onSortChanged"
          @filterChanged="onFilterChanged"
          @grid-ready="onGridReady"
        /> -->
        <!-- <ag-grid-no-data v-else :grid-options="gridOptions" :column-defs="columnDefs" /> -->

        <b-pagination
          v-model="pagination.currentPage"
          class="my-1 float-right mr-2"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

    </b-card>
  </b-overlay>
</template>

<script>
import AgGridSelectPageSize from '@/components/AgGridSelectPageSize.vue'

import CellRendererActions from './cell-renderer/CellRendererActions.vue'

export default {
  components: {
    AgGridSelectPageSize,

    CellRendererActions,
  },
  data() {
    return {
      isLoading: true,
      orders: [],

      // AgGrid
      context: null,
      gridApi: null,
      gridOptions: {
        suppressHorizontalScroll: true,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
        floatingFilterComponentParams: { suppressFilterButton: true }, // make right-hand button disappear,
      },
      columnDefs: [
        {
          headerName: 'Recipient Name',
          field: 'delivery_address.recipient',
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'Buyer Email',
          field: 'buyer',
          filter: true,
          sortable: true,
          width: 260,
        },
        
        {
          headerName: 'Status Transaction',
          field: 'status' ,
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'Total Price',
          field: 'total_price',
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'Product',
          field: 'product.product_name',
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'Buy On',
          field: 'created_at',
          filter: true,
          width: 150,
          valueFormatter: params => this.$formatDatetime(params.value),
        },
        {
          headerName: 'Actions',
          field: 'edit_delete',
          width: 150,
          type: 'rightAligned',
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      components: [],

      // server pagination
      previousFilters: {},
      filters: {},
      sorters: [],
      columns: [],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 10,
      },

      exportingCSV: false,
    }
  },

  beforeMount() {
    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api
    this.get_orders()
  },
  methods: {
    get_orders() {
      this.$http
        .get(`/api/marketplace/orders`)
        .then(response => {
          const { output } = response.data
          // console.log(`🚀 ~ file: MarketplaceTransactions.vue ~ line 152 ~ get_orders ~ output`, output)

          this.orders = output

          this.isLoading = false
        })
        .catch(error => {})
    },

    onCellChange(event) {},
    onSortChanged(event) {
      this.sorters = this.gridApi.getSortModel()

      this.get_company_users()
        .then(() => {
          this.adjustAgGridSize()
        })
    },
    onFilterChanged(event) {
      this.filters = this.gridApi.getFilterModel()

      setTimeout(() => {
        if (JSON.stringify(this.filters) !== JSON.stringify(this.previousFilters)) {
          this.get_company_users()
            .then(() => {
              this.adjustAgGridSize()

              this.previousFilters = this.filters

              setTimeout(() => {
                this.gridApi.setFilterModel(this.filters) // infinite loop
              }, 100)
            })

        }
      }, 1000)
    },
    onGridReady(event) {
      this.gridApi = this.gridOptions.api

      this.adjustAgGridSize()
    },
    adjustAgGridSize() {
      this.gridOptions.api.setDomLayout('autoHeight')
      this.$refs.agGridTable.$el.style.height = ''

      const gridVh = this.$getGridVh()
      const gridHeight = this.$refs.agGridTable.$el.clientHeight
      if (gridHeight > this.$vh(gridVh)) {
        this.gridOptions.api.setDomLayout('normal')
        this.$refs.agGridTable.$el.style.height = `${gridVh}vh`
      }
      this.$refs.agGridTable.$el.style.width = `99.9%`

      this.gridApi.sizeColumnsToFit()
    },

  },
}
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(16px);
}

</style>
